<template>
  <div class="orderManagementDetailsPageBox page-info-content">
    <Details-page :detail-obj="detailObj" :details-item-arr="detailsItemArr" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { impOrderFundFlowDetail } from '@/api/transactionData'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      detailsItemArr: [
        { label: '合同编号', value: 'contractNo', width: 200 },
        { label: '付款方', value: 'cmpName', width: 200 },
        { label: '收款方', value: 'oppCmpName', width: 200 },
        { label: '付款金额(元)', value: 'amount', width: 200 },
        { label: '科目名称', value: 'itemName' },
        { label: '收款账号', value: 'openAccNo' },
        { label: '付款日期', value: 'payDate' },
        { label: '付款凭证', value: 'payFile' },
        { label: '创建时间', value: 'createTime' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      impOrderFundFlowDetail(this.$route.query.id, res => {
        this.detailObj = res.data
      })
    }
  }
}
</script>
